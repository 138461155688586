import React, { useState, useEffect, useCallback } from "react";
import {
    Card,
    CardHeader,
    Table,
    Button,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import EeiServices from '../../service/eei';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-custom-build"
import DataTable from "react-data-table-component";
import { tableCustomStyle } from "utils";

const Article = (props) => {
    const [article, setArticle] = useState([]);
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);
    const [selectedFile, setSelectedFile] = useState();
    const [editModal, setEditModal] = useState(false);
    const toggleEditModal = () => setEditModal(!editModal);
    let previewRef = React.useRef();
    let previewEditRef = React.useRef();
    let imgRef = React.useRef();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState();
    const [categories, setCategories] = useState([]);
    const [active, setActive] = useState(true);
    const [url, setUrl] = useState(false);
    const [loading, setLoading] = useState(false);
    const [meta, setMeta] = useState({
        totalItems: 0
    });
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    }

    const [editArticleData, setEditArticleData] = useState({
        id: null,
        file: "",
        isactive: false,
    });

    const openEditModal = (item) => {
        setEditArticleData({
            id: item.id,
            file: item.file,
            image: item.image,
            url: item.url,
        });
        setTitle(item.title);
        setDescription(item.description);
        setSelectedFile(null);
        setCategory(item.article_category.id);
        setUrl(item.url);
        toggleEditModal();
    };

    const handleEditSubmit = async (ev) => {
        try {
            ev.preventDefault();
            const { id } = editArticleData;

            await editArticle(id);
            Swal.fire({
                icon: 'success',
                title: 'Article Saved!',
                text: 'The Article has been saved successfully.',
                confirmButtonText: 'OK',
            });
            fetchData();
            toggleEditModal();
        } catch (error) {
            console.error('Failed to submit edited banner:', error);

            Swal.fire({
                icon: 'error',
                title: 'Failed to Save Changes',
                text: 'An error occurred while trying to save the changes.',
            });
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const eeiServices = new EeiServices();
            const result = await eeiServices.getArticleActive(page, limit);
            setArticle(result.data);
            setMeta(result.meta)
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Error when get the data"
            })
        } finally {
            setLoading(false)
        }
    };

    const fetchCategory = async () => {
        try {
            const eeiServices = new EeiServices();
            const result = await eeiServices.getActiveArticleCategory();
            setCategories(result.data || []);
        } catch (error) {
            console.error("Failed to fetch category article data:", error);
        }
    }

    useEffect(() => {
        fetchData();
        fetchCategory();
    }, [page, limit]);

    const editArticle = async (id) => {
        try {
            const eeiServices = new EeiServices();
            await eeiServices.patchArticle(id, selectedFile, url, title, category, description, active);

            fetchData();
        } catch (error) {
            throw error;
        }
    };

    const handleSubmit = async (ev) => {
        try {
            ev.preventDefault();
            const eeiServices = new EeiServices();
            const payload = {
                file: selectedFile,
                article_category_id: category,
                title,
                description,
                url,
            }
            const result = await eeiServices.ArticleService(payload);
            console.log('Article posted successfully:', result);

            Swal.fire({
                icon: 'success',
                title: 'Article Posted!',
                text: 'The Article has been posted successfully.',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    toggleModal();
                }
            });

            setSelectedFile(null);
            setTitle('');
            setDescription('');
            setCategory(-1);
            fetchData();
        } catch (error) {
            console.error('Failed to post Article:', error);

            Swal.fire({
                icon: 'error',
                title: 'Failed to Post Article',
                text: 'An error occurred while trying to post the Article.',
            });
        }
    };

    const removeImg = useCallback(() => {
        setSelectedFile(null);
        setEditArticleData({ ...editArticleData, image: null });

        if (previewEditRef && previewEditRef.style) {
            previewEditRef.style.background = 'transparent'
        }
        if (previewRef && previewRef.style) {
            previewRef.style.background = 'transparent'
        }
        if (imgRef) {
            imgRef.value = '';
        }
    }, [selectedFile, editArticleData.image]);

    const deleteArticle = async (id) => {

        Swal.fire({
            icon: 'warning',
            title: 'Delete Article!',
            text: 'Are you sure to delete ?',
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const eeiServices = new EeiServices();
                const result = await eeiServices.deleteArticle(id);
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Article deleted successfully"
                })
                console.log('Article deleted successfully:', result);
                fetchData();
            }
        });
    }

    const onEditRefChange = useCallback(node => {
        if (node && selectedFile) {
            previewEditRef = node;
            previewEditRef.style.background = 'url("' + URL.createObjectURL(selectedFile) + '")';
        }
        else if (node && editArticleData.image) {
            previewEditRef = node;
            previewEditRef.style.background = 'url("' + editArticleData.image + '")';
        }

    }, [selectedFile, editArticleData.image]);

    const onRefChange = useCallback(node => {
        if (node && selectedFile) {
            previewRef = node;
            previewRef.style.background = 'url("' + URL.createObjectURL(selectedFile) + '")';
        }
    }, [selectedFile]);

    const columns = [
        {
            name: "File Image",
            selector: row => row.image,
            cell: row => {
                return <img style={{ width: "90px", height: "70px" }} src={row.image} alt="Course" />
            }
        },
        {
            name: 'URL',
            selector: row => row.url,
        },
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Actions',
            selector: row => row.id,
            cell: row => (
                <>
                    <Button
                        color="info"
                        size="sm"
                        className="mr-2"
                        onClick={() =>
                            openEditModal(row)
                        }
                    >
                        <FaEdit />
                    </Button>

                    <Button type="button" color="danger" size="sm" onClick={() => deleteArticle(row.id)} >
                        <FaTrash />
                    </Button>
                </>
            )
        }
    ];

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container className="mt-2" fluid>
                    <Row>
                        <div className="col">
                            <Card className="bg-default shadow">
                                <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                    <h3 className="text-white mb-0">Article Active</h3>
                                    <Button color="primary" onClick={() => {
                                        setTitle('');
                                        setCategory(-1);
                                        setDescription('');
                                        setSelectedFile(null);
                                        toggleModal();
                                    }}>
                                        <FaPlus className="mr-2" />
                                        Add
                                    </Button>
                                </CardHeader>
                                <DataTable
                                    customStyles={tableCustomStyle}
                                    columns={columns}
                                    data={article}
                                    progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={meta.totalItems}
                                    onChangePage={page => setPage(page)}
                                    onChangeRowsPerPage={rows => setLimit(rows)}
                                />
                            </Card>
                        </div>
                    </Row>
                </Container>

                {/* Add Modal */}
                <Modal isOpen={modal} toggle={toggleModal} size="xl">
                    <form onSubmit={handleSubmit} >
                        <ModalHeader toggle={toggleModal}>Add Article</ModalHeader>
                        <ModalBody className="pt-0 container-fluid" >
                            <div className="row" >
                                <div className="col-md-6" >
                                    <FormGroup >
                                        <Label for="fileInput">Title</Label>
                                        <Input
                                            type="text"
                                            id="title"
                                            defaultValue={title}
                                            onChange={ev => setTitle(ev.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup >
                                        <Label for="fileInput">Choose Image</Label>
                                        <input
                                            className="d-block"
                                            type="file"
                                            id="fileInput"
                                            accept="image/*"
                                            ref={el => imgRef = el}
                                            onChange={e => handleFileChange(e)}
                                            required
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-6 d-flex align-items-center justify-content-center" >
                                    <div className="position-relative preview-img" ref={onRefChange} style={{ width: '250px', height: '150px' }} >
                                        {selectedFile ?
                                            <button type="button" className="close position-absolute button-remove-img" onClick={removeImg} >
                                                <span aria-hidden="true" className="text-white" >×</span>
                                            </button>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-6" >
                                    <FormGroup >
                                        <Label for="category">Category</Label>
                                        <Input type="select" name="category" defaultValue={-1} onChange={ev => setCategory(ev.target.value)} required >
                                            <option value={-1}>Choose a category</option>
                                            {categories.map(cat =>
                                                <option key={cat.id} value={cat.id}>{cat.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-12" >
                                    <FormGroup >
                                        <Label for="description">Description</Label>
                                        <CKEditor
                                            config={{
                                                mediaEmbed: {
                                                    previewsInData: true,
                                                },
                                            }}
                                            editor={ClassicEditor}
                                            data={description}
                                            onChange={(_, data) => {
                                                setDescription(data.getData());
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-12" >
                                    <FormGroup >
                                        <Label for="url">Url</Label>
                                        <Input type="text" defaultValue={url} id="url" onChange={ev => setUrl(ev.target.value)} />
                                    </FormGroup>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" color="primary">
                                Post Article
                            </Button>
                            <Button color="secondary" onClick={toggleModal}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>

                {/* Edit Modal */}
                <Modal isOpen={editModal} toggle={toggleEditModal} size="xl">
                    <form onSubmit={handleEditSubmit} >
                        <ModalHeader toggle={toggleEditModal}>Edit Banner</ModalHeader>
                        <ModalBody className="pt-0 container-fluid" >
                            <div className="row" >
                                <div className="col-md-6" >
                                    <FormGroup >
                                        <Label for="fileInput">Title</Label>
                                        <Input
                                            type="text"
                                            id="title"
                                            defaultValue={title}
                                            onChange={ev => setTitle(ev.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup >
                                        <Label for="fileInput">Choose Image</Label>
                                        <input
                                            className="d-block"
                                            type="file"
                                            id="fileInput"
                                            accept="image/*"
                                            ref={el => imgRef = el}
                                            onChange={e => handleFileChange(e)}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-6 d-flex align-items-center justify-content-center" >
                                    <div className="position-relative preview-img" ref={onEditRefChange} style={{ width: '250px', height: '150px' }} >
                                        {selectedFile || editArticleData.image ?
                                            <button type="button" className="close position-absolute button-remove-img" onClick={removeImg} >
                                                <span aria-hidden="true" className="text-white" >×</span>
                                            </button>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-6" >
                                    <FormGroup >
                                        <Label for="category">Category</Label>
                                        <Input type="select" name="category" defaultValue={category} onChange={ev => setCategory(ev.target.value)} required >
                                            <option value={-1}>Choose a category</option>
                                            {categories.map(cat =>
                                                <option key={cat.id} value={cat.id} >{cat.name}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-12" >
                                    <FormGroup >
                                        <Label for="fileInput">Description</Label>
                                        <CKEditor
                                            config={{
                                                mediaEmbed: {
                                                    previewsInData: true,
                                                },
                                            }}
                                            editor={ClassicEditor}
                                            data={description}
                                            onChange={(_, data) => {
                                                setDescription(data.getData());
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-12" >
                                    <FormGroup >
                                        <Label for="url">Url</Label>
                                        <Input type="text" defaultValue={url} id="url" onChange={ev => setUrl(ev.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="col-12" >
                                    <FormGroup className="ml-4" >
                                        <Input type="checkbox" defaultChecked={active} id="active" onChange={ev => setActive(ev.target.value)} />
                                        <Label for="active">Active</Label>
                                    </FormGroup>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" color="primary" >
                                Save Changes
                            </Button>
                            <Button type="button" color="secondary" onClick={toggleEditModal}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>
        </>
    );
};

export default Article;