import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Table,
    Button,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import "react-quill/dist/quill.snow.css";
import EeiServices from "../../service/eei";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-custom-build"
import DataTable from 'react-data-table-component';
import { tableCustomStyle } from "utils";

const Course = (props) => {
    const [modal, setModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [nameContent, setNameContent] = useState("");
    const [titleContent, setTitleContent] = useState("");
    const [editorContent, setEditorContent] = useState("");
    const [priceContent, setPriceContent] = useState("");
    const [salePriceContent, setSalePriceContent] = useState("");
    const [measurementContent, setMeasurementContent] = useState("");
    const [discountContent, setDiscountContent] = useState("");
    const [stockOnHandContent, setStockOnHandContent] = useState("");
    const [permalinkContent, setPermalinkContent] = useState("");
    const [recommendedContent, setRecommendedContent] = useState("");
    const [featuredContent, setFeaturedContent] = useState("");
    const [typeIdContent, setTypeIdContent] = useState("");
    const [categoryIdContent, setCategoryIdContent] = useState("");
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [course, setCourse] = useState([]);
    const [image, setImage] = useState(null);
    const [is_published, setPublishedCourse] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
        setImage(null)
    };
    const [editModal, setEditModal] = useState(false);
    const toggleEditModal = () => setEditModal(!editModal);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        const reader = new FileReader()
        reader.onloadend = () => setImage(reader.result);
        reader.readAsDataURL(e.target.files[0])
    };
    const handleNameChange = (e) => setNameContent(e.target.value);
    const handleTitleChange = (e) => setTitleContent(e.target.value);
    const handlePriceChange = (e) => setPriceContent(e.target.value);
    const handleSalePriceChange = (e) => setSalePriceContent(e.target.value);
    const handleMeasurementChange = (e) => setMeasurementContent(e.target.value);
    const handleDiscountChange = (e) => setDiscountContent(e.target.value);
    const handleStockOnHandChange = (e) => setStockOnHandContent(e.target.value);
    const handlePermalinkChange = (e) => setPermalinkContent(e.target.value);
    const handleRecommendedChange = (e) => setRecommendedContent(e.target.value);
    const handleFeaturedChange = (e) => setFeaturedContent(e.target.value);
    const handleTypeIdChange = (e) => setTypeIdContent(e.target.value);
    const handleCategoryIdChange = (e) => setCategoryIdContent(e.target.value);
    const handleIsPublishedChange = (e) => setPublishedCourse(e.target.checked);
    const [loading, setLoading] = useState(false);
    const [meta, setMeta] = useState({
        totalItems: 0
    });
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);


    const columns = [
        {
            name: "File Image",
            selector: row => row.image,
            cell: row => {
                return <img style={{ width: "90px", height: "70px" }} src={row.thumbnail} alt="Course" />
            }
        },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Category Id',
            selector: row => row.category.name,
        },
        {
            name: 'Is Published',
            selector: row => row.is_published ? 'Published' : 'Unpublished',
        },
        {
            name: 'Actions',
            selector: row => row.id,
            cell: row => (
                <>
                    <Button color="info" size="sm" className="mr-2" onClick={() =>
                        openEditModal(row)
                    }>
                        <FaEdit />
                    </Button>
                    <Button color="danger" size="sm" onClick={() => handleDelete(row.id)}>
                        <FaTrash />
                    </Button>
                </>
            )
        }
    ];

    const [editCourseData, setEditCourseData] = useState({
        id: null,
        files: "",
        name: "",
        title: "",
        description: "",
        price: "",
        salePrice: "",
        measurement: "",
        discount: "",
        stockOnHand: "",
        permalink: "",
        recommended: false,
        isFeatured: false,
        is_published: false,
        typeId: 0,
        categoryId: 0,
    });

    const openEditModal = async (item) => {
        setLoadingEdit(true);
        setEditCourseData({
            id: item.id,
            files: item.files,
            name: item.name,
            title: item.title,
            description: item.description,
            price: item.price,
            salePrice: item.sale_price,
            measurement: item.measurement,
            discount: item.discount,
            stockOnHand: item.stock_on_hand,
            permalink: item.permalink,
            recommended: item.is_recommended,
            isFeatured: item.is_featured,
            typeId: item.typeId,
            categoryId: item.categoryId,
            is_published: item.is_published,
        });
        setImage(item.thumbnail);
        const res = await fetch(item.thumbnail);
        const blob = await res.blob();
        setSelectedFile(blob);
        setPublishedCourse(item.is_published);
        setLoadingEdit(false);

        toggleEditModal();
    };

    const handleDelete = async (id) => {
        try {
            const eeiServices = new EeiServices();
            Swal.fire({
                title: "Loading",
                async didOpen() {
                    Swal.showLoading();
                    await eeiServices.deleteCourse(id);
                    Swal.close();
                }
            });
            fetchData();

            Swal.fire({
                title: 'Course Deleted!',
                text: 'The course has been successfully deleted.',
                icon: 'success',
            });
        } catch (error) {
            console.error("Error Deleting Course:", error.message);
            Swal.fire({
                title: 'Error Deleting Course',
                text: 'An error occurred while deleting the course. Please try again.',
                icon: 'error',
            });
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            const { id, name, title, description, price, salePrice, measurement, discount, stockOnHand, permalink, recommended, isFeatured, typeId, categoryId } = editCourseData;

            Swal.fire({
                title: "Loading",
                async didOpen() {
                    Swal.showLoading();
                    await editCourse(id, selectedFile, name, title, description, price, salePrice, measurement, discount, stockOnHand, permalink, recommended, isFeatured, typeId, categoryId, is_published);
                    Swal.close();
                }
            });
            toggleEditModal();
            fetchData();
        } catch (error) {

            Swal.fire({
                icon: 'error',
                title: 'Failed to Save Changes',
                text: 'An error occurred while trying to save the changes.',
            });
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const eeiServices = new EeiServices();
            const result = await eeiServices.getCourse(page, limit);
            setCourse(result.data.map(v => ({ ...v, thumbnail: v.images })));
        } catch (error) {
            setCourse([]);
            Swal.fire({
                title: "Error",
                text: "Error when fetching data",
                timer: 3000
            }, null, "error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [limit, page]);

    const editCourse = async (id, files, name, title, description, price, salePrice, measurement, discount, stockOnHand, permalink, recommended, isFeatured, typeId, categoryId, is_published) => {
        try {
            const eeiServices = new EeiServices();
            // Call your patchBanner function here
            await eeiServices.patchCourse(id, files, name, title, description, price, salePrice, measurement, discount, stockOnHand, permalink, recommended, isFeatured, typeId, categoryId, is_published);

            // Optionally, you can reload the data after editing
            fetchData();
        } catch (error) {
            console.error('Failed to edit banner:', error);

            Swal.fire({
                icon: 'error',
                title: 'Failed to Edit Banner',
                text: 'An error occurred while trying to edit the banner.',
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const eeiServices = new EeiServices();

            const result = await eeiServices.CourseService(
                selectedFile,
                nameContent,
                titleContent,
                editorContent,
                priceContent,
                salePriceContent,
                measurementContent,
                discountContent,
                stockOnHandContent,
                permalinkContent,
                recommendedContent,
                featuredContent,
                typeIdContent,
                categoryIdContent,
                is_published,
            );

            console.log('Course posted successfully:', result);

            Swal.fire({
                icon: 'success',
                title: 'Course Posted!',
                text: 'The course has been posted successfully.',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    toggleModal();
                    window.location.reload();
                }
            });

            setEditorContent("");
            setNameContent("");
            setTitleContent("");
            setSelectedFile(null);
            setPriceContent("");
            setSalePriceContent("");
            setMeasurementContent("");
            setDiscountContent("");
            setStockOnHandContent("");
            setPermalinkContent("");
            setRecommendedContent("");
            setFeaturedContent("");
            setTypeIdContent("");
            setCategoryIdContent("");
            setPublishedCourse(true);
        } catch (error) {
            console.error('Failed to post course:', error);
            const message = error.message || 'An error occurred while trying to post the course.';

            Swal.fire({
                icon: 'error',
                title: 'Failed to Post course',
                text: message,
            });
        }
    };

    useEffect(() => {
        let price = editCourseData.price,
            discount = editCourseData.discount;
        const sale_price = (price - discount) < 0 ? 0 : (price - discount);
        setEditCourseData({
            ...editCourseData,
            salePrice: sale_price
        });

    }, [editCourseData.price, editCourseData.discount]);

    useEffect(() => {
        let price = priceContent,
            discount = discountContent;
        const sale_price = (price - discount) < 0 ? 0 : (price - discount);
        setSalePriceContent(sale_price);

    }, [priceContent, discountContent]);

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container className="mt-2" fluid>
                    <Row>
                        <div className="col">
                            <Card className="bg-default shadow">
                                <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                    <h3 className="text-white mb-0">Course Active</h3>
                                    <Button color="primary" onClick={toggleModal}>
                                        <FaPlus className="mr-2" />
                                        Add
                                    </Button>
                                </CardHeader>
                                <DataTable
                                    customStyles={tableCustomStyle}
                                    columns={columns}
                                    data={course}
                                    progressPending={loading}
                                    paginationTotalRows={meta.totalItems}
                                    pagination
                                    paginationServer
                                    onChangeRowsPerPage={rows => setLimit(rows)}
                                    onChangePage={page => setPage(page)}
                                />
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal} toggle={toggleModal} size="xl">
                <ModalHeader toggle={toggleModal}>Add Course</ModalHeader>
                <form onSubmit={handleSubmit} >
                    <ModalBody>

                        <FormGroup>
                            {image && (
                                <img src={image} style={{ maxWidth: "100%", maxHeight: "300px", objectFit: "cover", display: "block" }} />
                            )}
                            <Label for="fileInput">Choose File</Label>
                            <Input
                                type="file"
                                id="fileInput"
                                // value={selectedFile}
                                onChange={handleFileChange}
                                required
                                accept="image/*"
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="nameInput">Name</Label>
                            <Input
                                type="text"
                                id="nameInput"
                                value={nameContent}
                                onChange={handleNameChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="titleInput">Title</Label>
                            <Input
                                type="text"
                                id="titleInput"
                                value={titleContent}
                                onChange={handleTitleChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="editor">Description</Label>
                            <CKEditor
                                config={{
                                    mediaEmbed: {
                                        previewsInData: true,
                                    },
                                }}
                                editor={ClassicEditor}
                                data={editorContent || ''}
                                onChange={(_, data) => {
                                    setEditorContent(data.getData())
                                }}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="PriceInput">Price</Label>
                            <Input
                                type="number"
                                id="priceInput"
                                value={priceContent}
                                onChange={ev => {
                                    handlePriceChange(ev)
                                }}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="salePriceInput">Sale Price</Label>
                            <Input
                                type="number"
                                id="salePriceInput"
                                value={salePriceContent}
                                onChange={ev => {
                                    handleSalePriceChange(ev)
                                }}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="measurementInput">Measurement</Label>
                            <Input
                                type="number"
                                id="measurementInput"
                                value={measurementContent}
                                onChange={handleMeasurementChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="discountInput">Discount</Label>
                            <Input
                                type="number"
                                id="discountInput"
                                value={discountContent}
                                onChange={ev => {
                                    handleDiscountChange(ev)
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="stockOnHandInput">Stock On Hand</Label>
                            <Input
                                type="number"
                                id="stockOnHandInput"
                                value={stockOnHandContent}
                                onChange={handleStockOnHandChange}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="permalinkInput">Permalink</Label>
                            <Input
                                type="text"
                                id="permalinkInput"
                                value={permalinkContent}
                                onChange={handlePermalinkChange}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="recommendedInput">Recommended</Label>
                            <Input
                                type="select"
                                id="recommendedInput"
                                value={recommendedContent}
                                onChange={handleRecommendedChange}
                            >
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label for="featuredInput">Featured</Label>
                            <Input
                                type="select"
                                id="featuredInput"
                                value={featuredContent}
                                onChange={handleFeaturedChange}
                            >
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label for="typeIdInput">Type Id</Label>
                            <Input
                                type="number"
                                id="typeIdInput"
                                value={typeIdContent}
                                onChange={handleTypeIdChange}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="categoryIdInput">Category Id</Label>
                            <Input
                                type="number"
                                id="categoryIdInput"
                                value={categoryIdContent}
                                onChange={handleCategoryIdChange}
                            />
                        </FormGroup>

                        {/* <FormGroup>
                            <Input
                                type="checkbox"
                                id="is_published"
                                value={is_published}
                                onChange={handleIsPublishedChange}
                            />
                            <Label for="categoryIdInput">Is published</Label>
                        </FormGroup> */}


                        <FormGroup>
                            <Label for={"is_published"}>Is Published</Label>
                            <span className="clearfix" />
                            <label className="custom-toggle">
                                <input id="is-active-input" type="checkbox"
                                    value={is_published}
                                    onChange={handleIsPublishedChange} />
                                <span className="custom-toggle-slider rounded-circle" />
                            </label>
                        </FormGroup>
                    
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" >
                            Post Course
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={toggleEditModal} size="xl">
                <ModalHeader toggle={toggleEditModal} onClick={() => console.log(editCourseData)}>Edit Course</ModalHeader>
                <form onSubmit={handleEditSubmit} >
                    <ModalBody>
                        {loadingEdit ? "Loading...." : (
                            <>
                                <FormGroup>
                                    {image && (
                                        <img src={image} style={{ maxWidth: "100%", maxHeight: "300px", objectFit: "cover", display: "block" }} />
                                    )}
                                    <Label for="fileInput">Choose File</Label>
                                    <Input
                                        type="file"
                                        id="fileInput"
                                        onChange={handleFileChange}
                                        accept="image/*"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="editNameInput">Name</Label>
                                    <Input
                                        type="text"
                                        id="editNameInput"
                                        value={editCourseData.name}
                                        onChange={(e) =>
                                            setEditCourseData({ ...editCourseData, name: e.target.value })
                                        }
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="editTitleInput">Title</Label>
                                    <Input
                                        type="text"
                                        id="editTitleInput"
                                        value={editCourseData.title}
                                        onChange={(e) =>
                                            setEditCourseData({ ...editCourseData, title: e.target.value })
                                        }
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="editDescriptionInput">Description</Label>
                                    <CKEditor
                                        config={{
                                            mediaEmbed: {
                                                previewsInData: true,
                                            },
                                        }}
                                        editor={ClassicEditor}
                                        data={editCourseData.description || ''}
                                        onChange={(_, data) => {
                                            setEditCourseData({ ...editCourseData, description: data.getData() })
                                        }}
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="editPriceInput">Price</Label>
                                    <Input
                                        type="number"
                                        id="editPriceInput"
                                        value={editCourseData.price}
                                        onChange={(e) => {
                                            setEditCourseData({ ...editCourseData, price: e.target.value })
                                        }}
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="editsalePriceInput">Sale Price</Label>
                                    <Input
                                        type="number"
                                        id="editsalePriceInput"
                                        value={editCourseData.salePrice}
                                        onChange={(e) => {
                                            setEditCourseData({ ...editCourseData, salePrice: e.target.value })
                                        }}
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="editmeasurementInput">Measurement</Label>
                                    <Input
                                        type="number"
                                        id="editmeasurementInput"
                                        value={editCourseData.measurement}
                                        onChange={(e) =>
                                            setEditCourseData({ ...editCourseData, measurement: e.target.value })
                                        }
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="editdiscountInput">Discount</Label>
                                    <Input
                                        type="number"
                                        id="editdiscountInput"
                                        value={editCourseData.discount}
                                        onChange={(e) => {
                                            setEditCourseData({ ...editCourseData, discount: e.target.value })
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="editstockOnHandInput">StockOnHand</Label>
                                    <Input
                                        type="number"
                                        id="editstockOnHandInput"
                                        value={editCourseData.stockOnHand}
                                        onChange={(e) =>
                                            setEditCourseData({ ...editCourseData, stockOnHand: e.target.value })
                                        }
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="editpermalinkInput">Permalink</Label>
                                    <Input
                                        type="text"
                                        id="editpermalinkInput"
                                        value={editCourseData.permalink}
                                        onChange={(e) =>
                                            setEditCourseData({ ...editCourseData, permalink: e.target.value })
                                        }
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="editrecommendedInput">Recommended</Label>
                                    <Input
                                        type="boolean"
                                        id="editrecommendedInput"
                                        value={editCourseData.recommended}
                                        onChange={(e) =>
                                            setEditCourseData({ ...editCourseData, recommended: e.target.value })
                                        }
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="editisFeaturedInput">Featured</Label>
                                    <Input
                                        type="boolean"
                                        id="editisFeaturedInput"
                                        value={editCourseData.isFeatured}
                                        onChange={(e) =>
                                            setEditCourseData({ ...editCourseData, isFeatured: e.target.value })
                                        }
                                    />
                                </FormGroup>



                                <FormGroup>
                                    <Label for="edittypeIdInput">Type Id</Label>
                                    <Input
                                        type="number"
                                        id="edittypeIdInput"
                                        value={editCourseData.typeId}
                                        onChange={(e) =>
                                            setEditCourseData({ ...editCourseData, typeId: e.target.value })
                                        }
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="editcategoryIdInput">Category Id</Label>
                                    <Input
                                        type="number"
                                        id="editcategoryIdInput"
                                        value={editCourseData.categoryId}
                                        onChange={(e) =>
                                            setEditCourseData({ ...editCourseData, categoryId: e.target.value })
                                        }
                                    />
                                </FormGroup>

                                {/* <FormGroup>
                                    <Input
                                        type="checkbox"
                                        checked={editCourseData.is_published}
                                        onChange={e => setEditCourseData({ ...editCourseData, is_published: e.target.checked })}
                                    />
                                    <Label>Is Published</Label>
                                </FormGroup> */}

                                <FormGroup>
                                    <Label for={"is_published"}>Is Published</Label>
                                    <span className="clearfix" />
                                    <label className="custom-toggle">
                                        <input id="is-active-input" type="checkbox"
                                            checked={is_published}
                                            onChange={handleIsPublishedChange} />
                                        <span className="custom-toggle-slider rounded-circle" />
                                    </label>
                                </FormGroup>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={loadingEdit}>
                            Save Changes
                        </Button>
                        <Button color="secondary" onClick={toggleEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
};

export default Course;
