import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardBody, Container, Row, Col, CardText, Button, Alert } from "reactstrap";
import EeiServices from "service/eei";
import Swal from "sweetalert2";

const ChatCard = () => {
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchChats = async () => {
            setLoading(true);
            try {
                const eeiServices = new EeiServices();
                const result = await eeiServices.getChatMonitoring(page, limit);
                setChats(result.data);
                setTotalData(result.total || 0);
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to fetch chat data",
                });
            } finally {
                setLoading(false);
            }
        };
        fetchChats();
    }, [page, limit]);

    // func to redirect to chatdetail.js ( pass the id )
    const handleChatClick = (chat) => {
        navigate(`/admin/chat/${chat.id}`);
        console.log(chat.id)
    };


    return (
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <Container fluid className="mt-2">
                <Row>   
                    <Col>
                        <Card className="bg-default shadow w-100">
                            <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                <h3 className="text-white mb-0">Chat Monitoring</h3>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-3 text-white" style={{ minWidth: "130px" }}>Rows per page:</span>
                                        <select
                                            className="form-control"
                                            value={limit}
                                            onChange={(e) => {
                                                const newLimit = Number(e.target.value);
                                                setLimit(newLimit);
                                                setPage(1);
                                            }}
                                        >
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                        </select>
                                    </div>
                            </CardHeader>
                            <hr className="my-0 bg-white opacity-2" />
                            <CardBody>
                                {loading ? (
                                    <p className="text-light">Loading...</p>
                                ) : chats.length === 0 ? (
                                    <Alert color="danger">Chat is empty</Alert>
                                ) : (
                                    chats.map((chat, index) => (
                                        <Card 
                                            key={index} 
                                            className="mb-3 bg-secondary text-black cursor-pointer" 
                                            onClick={() => handleChatClick(chat)}
                                            style={{
                                                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                                cursor: "pointer"
                                            }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.transform = "translateY(-5px)";
                                                e.currentTarget.style.boxShadow = "0 10px 20px rgba(0,0,0,0.2)";
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.transform = "translateY(0)";
                                                e.currentTarget.style.boxShadow = "none";
                                            }}
                                        >
                                            <CardHeader className="d-flex align-items-center">
                                                <FaUser size={25} className="mr-2" />
                                                <h4 className="mb-0">{chat.name}</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <CardText><h2>{chat.last_message?.message}</h2></CardText>
                                                <CardText>
                                                    <small className="text-muted">
                                                        {new Date(chat.updated_at).toLocaleString()}
                                                    </small>
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    ))
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ChatCard;
