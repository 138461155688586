import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaUser, FaArrowLeft } from "react-icons/fa";
import { Card, CardHeader, CardBody, Container, Row, Col, CardText, Button, Alert, CardFooter } from "reactstrap";
import EeiServices from "service/eei";
import Swal from "sweetalert2";

const ChatDetail = () => {
    const { chatId } = useParams();
    const mainUserId = Number(chatId);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                setLoading(true);
                const eeiServices = new EeiServices();
                const result = await eeiServices.getDetailChatMonitoring(chatId);
                if (Array.isArray(result)) {
                    setMessages(result);
                } else if (result && typeof result === "object") {
                    const messagesData = result.data || result.messages || result.items || [];
                    setMessages(Array.isArray(messagesData) ? messagesData : []);
                } else {
                    setMessages([]);
                }
            } catch (error) {
                console.error("Failed to fetch chat messages", error);
                setMessages([]);
            } finally {
                setLoading(false);
            }
        };
        fetchMessages();
    }, [chatId]);

    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <Container fluid className="mt-2">
                <Row>
                    <Col>
                        <Card className="bg-default shadow w-100">
                            <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                <h3 className="text-white mb-0">
                                    Chat Monitoring User : {messages[0]?.user?.name || 'Unknown User'}
                                </h3>
                                <Button color="primary" size="md" onClick={handleGoBack}>
                                    <FaArrowLeft size={20} />
                                </Button>
                            </CardHeader>
                            <hr className="my-0 bg-white opacity-2" />
                            <CardBody>
                                {loading ? (
                                    <p className="text-light text-center py-4">
                                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </p>
                                ) : messages.length === 0 ? (
                                    <Alert color="warning" className="text-center">
                                        No messages found for this conversation
                                    </Alert>
                                ) : (messages.map((msg) => {
                                    const isOutgoing = msg.user && Number(msg.user.id) === mainUserId;
                                    return (
                                        <div
                                            key={msg.id}
                                            className={`mb-3 d-flex ${isOutgoing ? "justify-content-end" : "justify-content-start"}`}
                                        >
                                            {!isOutgoing && (
                                                <div className="mr-2" style={{ paddingTop: "4px" }}>
                                                    <FaUser size={24} className="text-white" />
                                                </div>
                                            )}
                                            <Card
                                                style={{
                                                    maxWidth: "70%",
                                                    backgroundColor: isOutgoing ? "#DCF8C6" : "#E8E8E8",
                                                    borderRadius: "15px"
                                                }}
                                                className="shadow-sm"
                                            >
                                                <CardBody className="py-2 px-3">
                                                    <strong className="d-block mb-1" style={{ color: "#075E54" }}>
                                                        {msg.user && msg.user.name ? msg.user.name : "Unknown"}
                                                    </strong>
                                                    <CardText className="mb-1" style={{ color: "#000" }}>
                                                        {msg.message}
                                                    </CardText>
                                                    <div className="d-flex justify-content-end">
                                                        <small className="text-muted">
                                                            {new Date(msg.updated_at).toLocaleTimeString([], {
                                                                hour: "2-digit",
                                                                minute: "2-digit"
                                                            })}
                                                        </small>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    );
                                })
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ChatDetail;
